import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { TranslationService } from './modules/i18n';
// language list
import { locale as enLang } from './modules/i18n/vocabs/en';
import { locale as chLang } from './modules/i18n/vocabs/ch';
import { locale as esLang } from './modules/i18n/vocabs/es';
import { locale as jpLang } from './modules/i18n/vocabs/jp';
import { locale as deLang } from './modules/i18n/vocabs/de';
import { locale as frLang } from './modules/i18n/vocabs/fr';
import { ThemeModeService } from './_metronic/partials/layout/theme-mode-switcher/theme-mode.service';
import { Capacitor } from '@capacitor/core';


import { Platform } from '@ionic/angular';
import { App as CapacitorApp } from '@capacitor/app';
import { Router } from '@angular/router';
import { Network } from '@capacitor/network';





@Component({
  // tslint:disable-next-line:component-selector
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'body[root]',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppComponent implements OnInit {
  constructor(
    private translationService: TranslationService,
    private modeService: ThemeModeService,
    private platform: Platform,
    private router: Router
  ) {
    // register translations
    this.translationService.loadTranslations(
      enLang,
      chLang,
      esLang,
      jpLang,
      deLang,
      frLang
    );

    // this.initializeApp();
  }


  isConnected = true;


  async ngOnInit() {
    this.modeService.init();

      // Initial network status
      const status = await Network.getStatus();
      this.isConnected = status.connected;

      // Listen for network status changes
      Network.addListener('networkStatusChange', (status) => {
        this.isConnected = status.connected;
        if (!this.isConnected) {
          // this.showNoInternetAlert();
        }
      });



    if ('serviceWorker' in navigator) {
      navigator.serviceWorker
        .register('/firebase-messaging-sw.js')
        .then((registration) => {
          console.log('Service Worker registered with scope:', registration.scope);
        })
        .catch((err) => {
          console.error('Service Worker registration failed:', err);
        });
    }

  }

// Back Button
  // initializeApp() {
  //   this.platform.ready().then(() => {
  //     if (Capacitor.isNativePlatform()) {
  //       CapacitorApp.addListener('backButton', ({ canGoBack }) => {
  //         // Conditional handling based on the current route
  //         console.log("asdsd",this.router.url);
  //         if (!canGoBack) {
  //           const currentUrl = this.router.url;
  //           if (currentUrl === '/dashboard') {
  //             // If on the dashboard, exit the app
  //             CapacitorApp.exitApp();
  //           } else if (currentUrl === '/auth/login') {
  //             // If on the login page, exit the app
  //             CapacitorApp.exitApp();
  //           } else {
  //             // For other pages, navigate back
  //             window.history.back();
  //           }
  //         } else {
  //           window.history.back();
  //         }
  //       });
  //     }
  //   });
  // }
}
