<!-- <div *ngIf="isConnected" class="no-internet">
  <span class="icon">⚠️</span>
  <p>Not connected to the internet.</p>
  <a href="tel:080031531">Call: 080031531</a>
</div> -->



<a href="tel:080031531" *ngIf="!isConnected" class="no-internet">
  <div class="no-internet-card">
    <div class="content">
      <span class="icon">⚠️</span>
      <p>Not connected to the internet.</p>
      <a  class="highlight-dial">📞 Call: 080031531</a>
    </div>
  </div>
</a>




<router-outlet></router-outlet>
